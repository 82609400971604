import React from 'react';
import { ToastContainer as RTToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'; // TODO: this is not working and the css is included in BundleConfig, needs to be fixed

const SHOWING_TOAST_IN_MS = 5000;

const ToastContainer = () => {
    return (
        <RTToastContainer
            position="top-right"
            autoClose={SHOWING_TOAST_IN_MS}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
        />
    );
};

export default ToastContainer;
