import React from 'react';
import { createRoot } from 'react-dom/client';
import ToastContainer from './ToastContainer';

const rootElementId = 'new-toast';
const container = document.getElementById(rootElementId);
if (container) {
    const root = createRoot(container);
    root.render(<ToastContainer />);
} else {
    throw new ReferenceError(`Unable to initialize React component. Element id '${rootElementId}' not found`);
}
